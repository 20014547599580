.node > label {
  cursor: pointer;
}

.tag-list {
  display: inline;
  padding: 0;
  margin: 0;
}

.tag-item {
  overflow: hidden;
  max-width: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  margin: 4px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
}

.tag-item:last-child {
  margin-right: 4px;
}
