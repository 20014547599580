:root {
  --form-control-color: #224f7a;
  --form-control-disabled: #959495;
}

.tag {
  background-color: #f4f4f4;
  border: 1px solid #e9e9e9;
  padding: 2px 0 2px 2px;
  border-radius: 2px;
  display: inline-block;
}
.tag:focus-within {
  background-color: #e9e9e9;
  border-color: #a0a0a0;
}
.tag-remove {
  color: #a0a0a0;
  font-size: 75%;
  line-height: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}
.tag-remove.disabled,
.tag-remove.readOnly {
  cursor: not-allowed;
}
.tag-remove:focus {
  color: #3c3c3c;
}
.tag-list {
  display: inline;
  padding: 0;
  margin: 0;
}
.tag-item {
  display: inline-block;
  margin: 4px;
}
.tag-item .search {
  border: none;
  border-bottom: 1px solid #cbd5e1;
  outline: none;
}
.tag-item:last-child {
  margin-right: 4px;
}

.node.leaf.collapsed {
  display: none;
}
.node.disabled > * {
  color: gray;
  cursor: not-allowed;
}
.node.match-in-children.hide .node-label {
  opacity: 0.5;
}

.toggle {
  white-space: pre;
  margin-right: 4px;
  cursor: pointer;
}
.toggle:after {
  content: ' ';
}
.toggle.collapsed:after {
  content: '+';
}
.toggle.expanded:after {
  content: '-';
}
.searchModeOn .toggle {
  display: none;
}
.react-dropdown-tree-select .dropdown {
  position: relative;
  display: table;
}

.react-dropdown-tree-select .dropdown .close-icon svg {
  height: 24px;
  width: 24px;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger {
  line-height: 20px;
  max-height: 200px;
  display: inline-block;
  overflow: auto;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
  cursor: pointer;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled {
  cursor: not-allowed;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled.bottom:after {
  color: #b9b9b9;
}

.react-dropdown-search {
  width: 100%;
  border: none;
  border-bottom: 1px solid #cbd5e1;
  outline: none;
}

.node.leaf > label {
  margin: 0 !important;
  white-space: nowrap;
  height: 37px;
}

.node.leaf .MuiCheckbox-root,
.node.leaf .MuiRadio-root {
  padding: 0 9px 0 9px !important;
}

.tag {
  background-color: #f4f4f4;
  border: 1px solid #e9e9e9;
  padding: 2px 0 2px 2px;
  border-radius: 2px;
  display: inline-block;
}
.tag:focus-within {
  background-color: #e9e9e9;
  border-color: #a0a0a0;
}
.tag-remove {
  color: #a0a0a0;
  font-size: 75%;
  line-height: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}
.tag-remove.disabled,
.tag-remove.readOnly {
  cursor: not-allowed;
}
.tag-remove:focus {
  color: #3c3c3c;
}
.node > label {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  margin-left: 2px;
  width: 100%;
}
.tag-list {
  display: inline;
  padding: 0;
  margin: 0;
}
.tag-item {
  display: inline-block;
  margin: 4px;
}
.tag-item .search {
  border: none;
  border-bottom: 1px solid #cbd5e1;
  outline: none;
}
.tag-item:last-child {
  margin-right: 4px;
}
.node {
  list-style: none;
  white-space: nowrap;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.node:hover {
  background-color: #f0f9ff;
}

.node.leaf.collapsed {
  display: none;
}
.node.leaf.checked {
  background-color: #e0f2fe;
}
.node.leaf.checked:hover {
  background-color: #f0f9ff;
}

.node.tree label {
  font-weight: 500;
  text-transform: uppercase;
}
.node.disabled > * {
  color: gray;
  cursor: not-allowed;
}
.node.match-in-children.hide .node-label {
  opacity: 0.5;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}
.toggle {
  white-space: pre;
  margin-right: 4px;
  cursor: pointer;
  font-size: 20px;
}
.toggle:after {
  content: ' ';
}

.toggle.collapsed:after {
  content: '+';
}
.toggle.expanded:after {
  content: '-';
}
.searchModeOn .toggle {
  display: none;
}

.checkbox-item,
.radio-item {
  vertical-align: middle;
  margin: 0 6px 0 0;
}

.react-dropdown-tree-select .dropdown {
  position: relative;
  display: table;
  width: 100%;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger {
  line-height: 20px;
  max-height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  width: 100%;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
  cursor: pointer;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled {
  cursor: not-allowed;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled.bottom:after {
  color: #b9b9b9;
}

.mb {
  margin-bottom: 10px;
}

input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
}

.selectSearchWrapper {
  margin: 6px;
  margin-bottom: 0px !important;
}

.styleNoDepth {
  margin: 6px !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
  min-height: 37px;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
  display: flex;
  padding-left: 12px;
  min-height: 1.1875em;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow svg {
  margin-top: -4px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.54);
}

.tag-item {
  margin: 0px;
}

.tag-list span {
  color: #94a3b8;
}

.node img {
  height: 8px;
  margin-right: 5px;
}

.dropdown-error-text {
  margin-top: 3px;
}

.selectAllWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 16px;
  height: 37px;
  padding: 8px 16px 8px 16px;
  width: 100%;
  margin-bottom: 0px !important;
}

.no-matches {
  height: 37px;
  padding: 8px 16px 8px 16px;
}

.selectAllWrapper label {
  width: 100%;
  margin-left: 8px !important;
  cursor: pointer;
}

.selectAllWrapper:hover {
  background-color: #f0f9ff;
  cursor: pointer;
}

.dropdown-content .search {
  width: 100%;
  border: none;
  outline: none;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 4px;
}
.dropdown-content .search::placeholder {
  color: #757575;
}

.dropdown-content ul {
  margin: 0;
  padding: 0;
}

.dropdown-content ul li {
  height: 37px;
  padding: 8px 5px 8px 16px;
  width: fit-content;
  min-width: 100%;
}
