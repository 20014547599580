.node {
  list-style: none;
  white-space: nowrap;
  padding: 2px 0;
}

.toggle {
  white-space: pre;
  margin-right: 4px;
  cursor: pointer;
}

.searchModeOn .toggle {
  display: none;
}

.checkbox-item,
.radio-item {
  vertical-align: middle;
  margin: 0 4px 0 0;
}
