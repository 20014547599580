.tag-menu {
  border: 1px solid #ddd;
  padding: 5px;
  padding-bottom: 12.5px;
  border-radius: 4px;
  white-space: normal;
  text-align: left;
}
.tag-menu-content {
  height: 22px;
  overflow: hidden;
}
.tag-menu-content .scrollbar-container {
  display: flex;
  /* flex-flow: row wrap; */
  gap: 5px;
}

.tag-placeholder {
  font-size: 14px;
  color: #a9a9ac;
}

.operator-button {
  margin-top: -12px;
  height: 25px;
  text-align: center;
}
