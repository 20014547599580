// Overwrite css

// CARD
.card-box {
  .no-radius {
    border-radius: 0px;
  }
}

//Date picker
.MuiPickersCalendarHeader-switchHeader {
  margin: 5px;
}

.border-bottom-radius {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.border-top-radius {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

//bkg
.background-gray-light {
  background: #fcfcfc;
}

// react-calendly dans une modale
.MuiDialogContent-root .calendly-spinner {
  // Le spinner n'arrivais pas à s'afficher correctement car le z-index était trop bas
  z-index: 1 !important;

  & > div {
    // Faire ressortir le spinner (plutôt qu'un gris très clair)
    background-color: #224f7a !important;
  }
}
